import '../styles/article.css';
import Logo from "../assets/banner.png";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getPost, getContent} from "../api/api";
import Content from "./Content";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import RelatedTopics from "./RelatedTopics";

const Article = (props) => {
    const [post, setPost] = useState('')
    const {id} = useParams()
    const navigate = useNavigate();
    const [content, setContent] = useState([]);

    const DEBUG = false;
    let path = "https://vibehubinfo.com";
    if (DEBUG) {
        path = "http://localhost:3000";
    }

    const slicePost = function (data) {
        for (let i = 0; i < Object.keys(data).length; i++) {
            const index = Object.keys(data)[i];
            data[index].content = data[index].content.slice(0, 180) + "[...]"
        }
        return data
    };

    useEffect(() => {
        (async () => {
            const data = await getContent();
            let newContent = slicePost(data);
            setContent(newContent);
        })();
    }, []);


    useEffect(() => {
        var url = new URL(window.location.href);
        if (url.searchParams.has("red")) {
            let redUrl = window.location.href.split("?red=")[1];
            setTimeout(function () {
                window.location.href = decodeURIComponent(redUrl);
            }, 200);
        } else {
            (async () => {
                try {
                    const info = await getPost(id)
                    setPost(info)
                } catch (e) {
                    console.log(e);
                    navigate('*');
                }
            })();
        }
    }, [id, navigate])

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <Content>
                <div className='full-article-container'>
                    <img className='image' src={`${path}/posts-images/${post.image}`} alt="null" fetchpriority="high"/>
                    <div className='container'>
                        <div dangerouslySetInnerHTML={{__html: post.html1}}></div>
                        {post && <RelatedTopics category={post.category}/>}
                        <div dangerouslySetInnerHTML={{__html: post.html2}}></div>
                    </div>
                </div>
            </Content>
            <Footer/>
        </div>
    );
}

export default Article